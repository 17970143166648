@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.background {
  background-image: url('img/background.jpg');
  background-size: cover;
}

.time {
  font-family: monospace;
}
